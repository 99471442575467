import axios from "axios";


const getSignRequest = async (path, method, payload) => { 
    
    const token = window.localStorage.getItem(process.env.REACT_APP_TOKEN_PATH)

    let request = {         
        method: method,
        mode: 'cors',
        url: process.env.REACT_APP_HTTP_URL + path,
        data: payload,
       
    } 
    if(token) {
        request['headers'] = {
            Authorization: `Bearer ${token}`
        }
    }

    var instance = await axios.create();

    return instance(request)
}

const getService = ({ url, payload }) => getSignRequest(url, 'GET', payload ?  payload : {});
const postService = ({ url, payload }) => getSignRequest(url, 'POST', payload);
const putService = ({ url, payload }) => getSignRequest(url,'PUT', payload);
const deleteService = ({ url, payload }) => getSignRequest(url, 'DELETE', payload);



const services = {
    getService,
    postService,
    putService,
    deleteService
}

export default services;
