import { useState } from "react";
import useAuth from 'src/hooks/useAuth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// eslint-disable-next-line
export default (apiFunc) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); 
  const { logout } = useAuth();

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      allowOutsideClick: false,
      action: alert.action,
      didClose: alert.action ? alert.action : () => { console.log("Actioned") }
    });
  };

  const request = async (...args) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      console.log("result", result)
      if(result.data.errorMessage) {
        result.data.errorMessage = result.data.errorMessage.replace('An error occurred (NotAuthorizedException) when calling the InitiateAuth operation: ', '').replace('An error occurred (UserNotFoundException) when calling the AdminGetUser operation: ', '')
      }
      if(result.data.message) {
        result.data.message = result.data.message.replace('An error occurred (NotAuthorizedException) when calling the InitiateAuth operation: ', '').replace('An error occurred (UserNotFoundException) when calling the AdminGetUser operation: ', '')
      }
      if(result && !('success' in result) && result.data.errorMessage) {
        result['success'] = false
        result.data['message'] = result.data.errorMessage ? result.data.errorMessage : 'Something went wrong'
      }
      setData(result.data ? result.data : result);
    } catch (err) {
      const response = { ...err.response }
      console.log("errResponse",{...response})
      if(response.status === 401 || response.status === 403) {
        logout()
        sweetAlertHandler({ type: 'danger', text: "Login Expired !", showConfirmButton: true, action: () => {
          logout()
        }})
      } else if(response.status === 400) {
        if(response.data && response.data.message && response.data.message.indexOf('missing') > -1) {
          sweetAlertHandler({ type: 'danger', text: "Login Expired !", showConfirmButton: true, action: () => {
            logout()
          }})
        } else {
          sweetAlertHandler({ type: 'danger', text: "Something went wrong !!, Please try again later", showConfirmButton: true})
        }
      }
      setError(err.message || "Unexpected Error!");
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request
  };
};
