import React, { useContext, useEffect, useRef, useState } from 'react';
import { ListGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import Logo from "src/assets/images/common/logo-slo.svg";
import AmazonLogo from "src/assets/images/common/amazon.svg";


import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';

import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ConfigContext } from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';

import queryString from 'query-string';
import useApi from 'src/hooks/useApi';
import services from 'src/api/service';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { NavLink, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'src/components/Actions/Button';
import { Link } from 'react-router-dom';

// print (auth_url) #https://hectorai.netlify.app/aws/callback?spapi_oauth_code=RHVgzUOFoBYpVOTIoEhg&state=IN&selling_partner_id=A2KMPVLFO6FIMC

const AdminLayout = ({ children }) => {
  const ParseData = JSON.parse(localStorage.getItem('User'));

  const queryParams = queryString.parse(window.location.search);
  const { code, scope } = queryParams;
  const { spapi_oauth_code, state, selling_partner_id } = queryParams;

  const isAMS = code && scope ? true : false;
  const isSeller = spapi_oauth_code && state && selling_partner_id ? true : false;

  const doAMSQueryParam = useApi(services.postService);
  const doAMSProfiles = useApi(services.postService);
  const doSellerQueryparam = useApi(services.postService);
  const doSellerProfiles = useApi(services.postService);
  const doGetProfiles = useApi(services.postService);

  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const [activeProfiles, setActiveProfiles] = useState([]);
  const [inActiveProfiles, setInActiveProfiles] = useState([]);

  const { collapseMenu, layout, subLayout, headerFixedLayout, configBlock } = configContext.state;
  const { dispatch } = configContext;
  const [isExit, setExitActive] = useState(false);
  const [exitOverlay, setexitOverlay] = useState(false);

  console.log('adminLayout--QueryParam', queryParams);
  let history = useHistory();


  useOutsideClick(ref, () => {
    if (collapseMenu) {
      setTimeout(() => {
        dispatch({ type: actionType.COLLAPSE_MENU });
      }, 3000);
    }
  });

  const getProfiles = () => {
    // doGetProfiles.request({ url: '/mdb-get-profiles', payload: { email: ParseData.email, token: ParseData.token, active: false } });
  };

  useEffect(() => {
    if (ParseData) {
      getProfiles();
    }
  }, []);

  // useEffect(() => {
  //   if (!doGetProfiles.loading) {
  //     const { data, loading } = doGetProfiles;
  //     console.log('doGetProfiles----data', data);

  //     let activeData =
  //       data?.data && data?.data?.profiles
  //         ? data?.data?.profiles.filter((active) => {
  //           return active.ams.connected === true;
  //         })
  //         : [];
  //     const defaultProfile = JSON.parse(localStorage.getItem('selectedProfileId'));
  //     if (!defaultProfile && activeData.length > 0) {
  //       window.location.reload();
  //       localStorage.setItem('selectedProfileId', JSON.stringify(activeData[0]));
  //     }
  //     setActiveProfiles(activeData);
  //     localStorage.setItem('ActiveProfilesAMS', JSON.stringify(activeData));
  //     let inActiveData =
  //       data?.data && data?.data?.profiles
  //         ? data?.data?.profiles.filter((inactive) => {
  //           return inactive.ams.connected === false;
  //         })
  //         : [];
  //     setInActiveProfiles(inActiveData);
  //   }
  // }, [doGetProfiles.loading]);

  useEffect(() => {
    const { data, loading } = doGetProfiles;
    console.log("doGetProfiles--data", data)

    if (!loading && data && data.data && data.success === true) {
      console.log("active--", activeProfiles)
      setActiveProfiles(data.data)
    } else if (!loading && data && data.success === false) {
      console.log("active--", activeProfiles)
      setActiveProfiles([])
    }

  }, [doGetProfiles.loading])

  useEffect(() => {
    if (queryParams && Object.keys(queryParams).length > 0) {
      console.log('AMS--adminLayout', queryParams);
      if (isAMS === true) {
        console.log('AMS--admin', queryParams);
        AMSqueryParamsApi(queryParams.code);
      } else if (isSeller === true) {
        console.log('ASP--adminLayout', queryParams);
        doSellerQueryParamsRetrive(queryParams);
      }
    }
  }, []);

  const goToProfile = () => {
    history.push('/profile');
  };

  const goToAddProfile = (routepage) => {
    if (routepage === 'addProfilePage') {
      history.push('/add-profile');
    } else if (routepage === 'thankyouPage') {
      history.push('/thankyou');
    }
  };

  useEffect(() => {
    if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
    }
  }, [dispatch, layout, windowSize]);

  useEffect(() => {
    const { data, loading } = doAMSQueryParam;
    console.log('doAMSQueryParam--AdminLayout', data);

    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['refresh_token'] = data.data.refresh_token;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      AMSretriveToken();
    }
  }, [doAMSQueryParam.loading]);

  useEffect(() => {
    const { data, loading } = doAMSProfiles;
    console.log('doAMSProfiles--AdminLayout', data);
    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data.profiles) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['profiles'] = data?.data?.profiles;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      localStorage.setItem(
        'AMSexistingProfiles',
        data?.data?.existing_profiles_ids ? JSON.stringify(data?.data?.existing_profiles_ids) : []
      );
      sweetAlertHandler({
        type: 'success',
        text: data.message ? data.message : 'Amazon AMS Authorization Successfully !!',
        showConfirmButton: true,
        action: goToAddProfile('addProfilePage')
      });
    }
  }, [doAMSProfiles.loading]);

  useEffect(() => {
    const { data, loading } = doSellerQueryparam;
    console.log('doSellerQueryparam--AdminLayout', data);

    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      if (localData) {
        localData['refresh_token'] = data.data.refresh_token;
        localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      }
      sweetAlertHandler({
        type: 'success',
        text: data.message ? data.message : 'Amazon Seller Central Authorization Successfully !!',
        showConfirmButton: true,
        action: goToAddProfile('thankyouPage')
      });
      // doSellerProfileRetrive()
    }
  }, [doSellerQueryparam.loading]);

  useEffect(() => {
    const { data, loading } = doSellerProfiles;
    console.log('doSellerProfiles--AdminLayout', data);
    if (!loading && data?.success === false) {
      sweetAlertHandler({ type: 'danger', text: data.message, showConfirmButton: true, action: goToProfile });
    } else if (!loading && data?.success === true && data.data.profiles) {
      let localData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
      localData['profiles'] = data.data.profiles;
      localStorage.setItem('AMSselectedCountry', JSON.stringify(localData));
      sweetAlertHandler({ type: 'success', text: data.message, showConfirmButton: true, action: goToProfile });
    }
  }, [doSellerProfiles.loading]);

  const AMSqueryParamsApi = (code) => {
    let ParseData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    if (ParseData && localUser) {
      doAMSQueryParam.request({
        url: '/ams-refresh-token',
        payload: { token: localUser.token, grant_code: code, marketplace: ParseData.region_name }
      });
    }
  };

  const AMSretriveToken = () => {
    let ParseData = JSON.parse(localStorage.getItem('AMSselectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    if (ParseData && localUser) {
      console.log('parseData', ParseData);
      doAMSProfiles.request({
        url: '/ams-retrieve-profiles',
        payload: { token: localUser.token, refresh_token: ParseData.refresh_token, marketplace: ParseData.region_name }
      });
    }
  };

  const doSellerQueryParamsRetrive = (queryParams) => {
    const parseData = JSON.parse(localStorage.getItem('ASPselectedCountry'));
    const localUser = JSON.parse(localStorage.getItem('User'));
    console.log('userFormAdmin', localUser);
    if (parseData && localUser && 'ams_profile' in parseData && parseData.ams_profile && parseData.ams_profile.profileId) {
      doSellerQueryparam.request({
        url: '/seller-refresh-token',
        payload: {
          token: localUser.token,
          grant_code: queryParams.spapi_oauth_code,
          marketplace: parseData.region_code,
          profile_id: parseData.ams_profile.profileId,
          selling_partner_id: queryParams.selling_partner_id
        }
      });
    }
  };

  const exitFullscreen = () => {
    const element = document.getElementById('panel-wrapper');
    if (document.fullscreenEnabled) {
      document?.exitFullscreen();
    }
    element.classList.remove('fscreen-enbld');
  };

  console.log('doAMSQueryParam', doAMSQueryParam);
  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: alert.text,
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action
        ? alert.action
        : () => {
          console.log('Actioned');
        },
      allowOutsideClick: false
    });
  };

  useEffect(() => {
    console.log('document?.fullscreen', document?.fullscreen);
    if (document?.fullscreen === true) {
      setexitOverlay(true);
    } else if (document?.fullscreen === true) {
      setexitOverlay(false);
    }

    return () => {
      setexitOverlay(false);
    };
  }, [document?.fullscreen]);

  useEffect(() => {
    console.log('document?.fullscreen', document?.fullscreen);
    if (document?.fullscreen === true) {
      setExitActive(false);
    } else if (document?.fullscreen === true) {
      setExitActive(false);
    }

    return () => {
      setExitActive(false);
    };
  }, [document?.fullscreen]);

  let common = (
    <React.Fragment>
      <Navigation />
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <NavBar activeProfiles={activeProfiles} />
      <div className="pcoded-main-container" id="panel-wrapper">
        {/* EXIT FULL SCREEN BUTTON */}
        {exitOverlay && <div className="fsch-hover" onMouseEnter={() => setExitActive(true)} onMouseLeave={() => setExitActive(false)} />}
        {isExit === true && (
          <div className="exit-btn hover-theme" onMouseEnter={() => setExitActive(true)}>
            {/* <DropdownButton className="text-capitalize txt-secondary" title="Targeting Summary" variant="white">
                      <Dropdown.Item eventKey="1" active>
                        <Link to="targeting-summary">Targeting Summary</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2">
                        <Link to="search-term">Search Term</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="3">
                        <Link to="profile">Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="4">
                        <Link to="addaccount">Add Account</Link>
                      </Dropdown.Item>
                    </DropdownButton> */}
            <i className="feather icon-minimize cursor-pointer hover-theme f-20 txt-secondary" onClick={exitFullscreen} />
            {/* <Button name="Exit" classes="txt-secondary hover-theme" action={exitFullscreen} /> */}
          </div>
        )}
        {/* EXIT FULL SCREEN BUTTON */}
        <div className={mainClass.join(' ')}>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              {/* <Breadcrumb /> */}
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div className="pcoded-outside" onClick={() => mobileOutClickHandler}>
        {mainContainer}
      </div>
    );
  }

  // return (
  //   <section className="h-screen d-flex flex-column align-items-center justify-content-center">
  //     {/* <Spinner animation="border" role="status" className="ml-2 wid-30 hei-30" variant="secondary" />
  //     <p>Getting AMS Refresh Token</p> */}


  //   <div class="fastforward mb-3">
  //     <section className="connect-logo">
  //       <img src={AmazonLogo} className="wid-50" /> 
  //     </section>

  //     {/* <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div>
  //     <div class="arrow"></div> */}

  //   <div class="load-wrapp">
  //     <div class="load-10">
  //       <div class="bar"></div>
  //     </div>
  //   </div>

  //   <section className="connect-logo">
  //     <img src={Logo} className="wid-50" />  
  //   </section>

  //   </div>

  //   <h4 className="f-w-600 f-18 text-center txt-secondary">Getting AMS Refresh Token</h4>  

  //   </section>
  // );

  if (doAMSQueryParam.loading || doAMSProfiles.loading || doSellerQueryparam.loading) {
    return (
      <section className="h-screen d-flex flex-column align-items-center justify-content-center">
        <div class="fastforward mb-3">
          <section className="connect-logo">
            <img src={AmazonLogo} className="wid-50" />
          </section>

          <div className="load-wrapp">
            <div className="load-10">
              <div className="bar"></div>
            </div>
          </div>

          <section className="connect-logo">
            <img src={Logo} className="wid-50" />
          </section>

        </div>
          <p>Authorization on progress...</p>
      </section>
    );
  }
  // else if (doAMSProfiles.loading) {
  //   return (
  //     <section className="h-screen d-flex flex-column align-items-center justify-content-center">
  //       <div className="fastforward mb-3">
  //         <section className="connect-logo">
  //           <img src={AmazonLogo} className="wid-50" />
  //         </section>

  //         <div className="load-wrapp">
  //           <div className="load-10">
  //             <div className="bar"></div>
  //           </div>
  //         </div>

  //         <section className="connect-logo">
  //           <img src={Logo} className="wid-50" />
  //         </section>

  //       </div>
  //         <p>Authorization on progress ...</p>
  //     </section>
  //   );
  // }
  // else if (doSellerQueryparam.loading) {
  //   return (
  //     <section className="h-screen d-flex flex-column align-items-center justify-content-center">
  //       <div className="fastforward mb-3">
  //         <section className="connect-logo">
  //           <img src={AmazonLogo} className="wid-50" />
  //         </section>

  //         <div className="load-wrapp">
  //           <div className="load-10">
  //             <div className="bar"></div>
  //           </div>
  //         </div>

  //         <section className="connect-logo">
  //           <img src={Logo} className="wid-50" />
  //         </section>

  //       </div>
  //         <p>Getting Seller Refresh Token</p>
  //     </section>
  //   );
  // }

  return (
    <React.Fragment>
      {common}
      {mainContainer}
      {configBlock}
    </React.Fragment>
  );
};

export default AdminLayout;
