import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/common/logo.svg';
import gmbLogo from 'src/assets/images/common/logo_v3.svg';
import logoWhite from 'src/assets/images/common/white-logo.svg';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import useAuth from 'src/hooks/useAuth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const UserProfile = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;
  const { logout } = useAuth()

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  const handleLogoutHandle = () => {
    sweetAlertHandler({ type: 'alert', text: 'Are you sure want to logout ?', showConfirmButton: true, CancelButton: true, })
  }

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: '',
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action ? alert.action : () => { console.log("Actioned") },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("isConfirmed")
        handleLogout()
        // showPoper()
        // setTimeout(() => {
          history.push("/")
        // }, 3000);
        
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  };

  return (
    <React.Fragment>
      <div className="user-details-footer">
        <Link to="#" className="b-brand" onClick={handleLogoutHandle}>
          <div className="b-bg">
            <i className="feather icon-log-out" />
          </div>
          <span className="b-title d-flex align-items-center">
          {/* <img src={gmbLogo} alt="logo" /> */}
          <p className="f-16 text-white f-w-600 m-l-10">Logout</p>
          </span>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
