import React, { useContext, useState, useEffect } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from './ChatList';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import useAuth from '../../../../hooks/useAuth';

// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import { DateRangePicker } from 'react-date-range';
// import { Popover, Whisper } from 'rsuite';
// import { addDays } from 'date-fns';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { NavLink, useHistory } from 'react-router-dom';
import userImg from 'src/assets/images/common/user.png';
import canadaFlag from 'src/assets/images/common/canada-flag.png';
import usFlag from 'src/assets/images/common/us-flag.png';
import indiaFlag from 'src/assets/images/common/india-flag.png';
import worldIcon from 'src/assets/images/common/world.svg';
import ExpandIcon from 'src/assets/images/common/expand.svg';

import { Maximize , Bell, User } from 'react-feather';


// const defaultActiveData =

const NavRight = (activeProfiles) => {
  let ParseData = JSON.parse(localStorage.getItem('User'))
  const localSelectedProfileId = JSON.parse(localStorage.getItem('selectedProfileId'))

  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout } = configContext.state;
  const queryParams = queryString.parse(window.location.search)
  const [listOpen, setListOpen] = useState(false);
  const [activeProfilesAMS, setAMSactiveProfiles] = useState([])
  const [defaultActive, setDefaultActive] = useState(localSelectedProfileId ? [localSelectedProfileId] : activeProfiles.activeProfiles?.slice(0, 1))

  
  let history = useHistory();

  const enableFullscreen = () => {
    const element = document.getElementById("panel-wrapper")
    element.requestFullscreen();
    element.classList.add("fscreen-enbld")
  }

  // console.log("defaultActive", defaultActive)
  

  useEffect(() => {
    if (activeProfiles?.activeProfiles?.length) {
      console.log("defaultActive-----", activeProfiles.activeProfiles)
      setAMSactiveProfiles(activeProfiles.activeProfiles)
      setDefaultActive(localSelectedProfileId ? [localSelectedProfileId] : activeProfiles.activeProfiles?.slice(0, 1))

      if(!localSelectedProfileId){
        console.log("selectedProfileId")
        localStorage.setItem('selectedProfileId', JSON.stringify(activeProfiles.activeProfiles?.slice(0, 1)))
      }
    }
  }, [activeProfiles?.activeProfiles])

  //   useEffect(()=>{
  //     if(activeProfilesAMS?.length){
  //     setDefaultActive(activeProfilesAMS?.slice(0, 1))
  // }
  // },[activeProfilesAMS])


  const handleSelect = (selectedItem) => {
    console.log("selectedItem", selectedItem)

    defaultActive.splice(0, 1)
    console.log("de", defaultActive)
    setDefaultActive([selectedItem])
    localStorage.setItem('selectedProfileId', JSON.stringify(selectedItem))
    window.location.reload()
  }

  // console.log("defaultActive", defaultActive)
  // useEffect(()=>{
  //   if(localSelectedProfileId)
  //   console.log("selectedProfileId")
  //   localStorage.setItem('selectedProfileId', JSON.stringify(defaultActive))
  // },[defaultActive])


  // const [datePickerOpen, setDatePickerOpen] = useState(false)


  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: 'selection'
  //   }
  // ]);

  // function DatePicker() {

  //   if (datePickerOpen === false) {
  //     setDatePickerOpen(true)

  //   } else if (datePickerOpen === true) {
  //     setDatePickerOpen(false)
  //   }
  // }

  // const speaker = (
  //   <Popover style={{ zIndex: "10000" }}>
  //     <div>

  //       <DateRangePicker
  //         onChange={item => setState([item.selection])}
  //         showSelectionPreview={true}
  //         moveRangeOnFirstSelection={false}
  //         months={2}
  //         ranges={state}
  //         direction="horizontal"
  //       ></DateRangePicker>
  //       {console.log("datepicker") }
  //       <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
  //         <button
  //           className="btn btn-transparent text-danger rounded-0 px-4" onClick={DatePicker}
  //         >
  //           Close
  //         </button>
  //       </div>
  //     </div>

  //   </Popover>
  // );

  const handleLogoutHandle = () => {
    sweetAlertHandler({ type: 'alert', text: 'Are you sure want to logout ?', showConfirmButton: true, CancelButton: true, })
  }

  const handleLogout = async () => {
    try {
      //handleClose();
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: alert.text,
      // text: '',
      type: alert.type,
      timer: alert.timer,
      showConfirmButton: alert.showConfirmButton,
      didClose: alert.action ? alert.action : () => { console.log("Actioned") },
      allowOutsideClick: false,
      showCancelButton: alert.CancelButton,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("isConfirmed")
        handleLogout()
        // showPoper()
        setTimeout(() => {
          history.push("/")
        }, 1000);
        
      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })
  };

  return (
    <React.Fragment>
      {/* <div style={{ }}>
      <Whisper
        preventOverflow
        placement="auto"
        speaker={speaker}
        open={datePickerOpen}
        trigger="none"
      >
        <input
          id="formControlsTextB"
          type="text"
          label="Text"
          // value={}
          onClick={DatePicker}
        />
      </Whisper>
      </div> */}
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">

        {/* {ParseData?.ActiveProfilesAMS?.length && */}
        <ListGroup.Item as="li" bsPrefix=" " hidden={activeProfilesAMS?.length === 0}>
          <Dropdown alignRight={!rtlLayout} className="select-account">

            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <div className="text-right d-flex flex-column l-base align-items-end pr-2">
                {/* <p className="f-w-600 f-14 d-flex align-items-center">
                  <img src={`https://img.mobiscroll.com/demos/flags/${defaultActive?.map((select) => select?.countryCode)}.png`} alt="" className="wid-30 mr-2" />
                  {defaultActive?.map((select) => select?.accountInfo?.name)}</p> */}
                {/* <small className="f-10 bg-blur px-2 py-1 rounded-10 txt-theme">{defaultActive?.map((select) => select?.countryCode)}</small> */}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight className="notification notification-scroll">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0 f-w-700 f-14">ACCOUNTS</h6>
              </div>
              <PerfectScrollbar className="min-hight-150 max-height-300">

                <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                  {activeProfilesAMS?.map((AMSprofiles, index) =>
                    <ListGroup.Item as="li" bsPrefix=" " className={'n-title px-0 pt-2'} key={index} onClick={() => handleSelect(AMSprofiles)}>
                      <Link to="#" className={`dropdown-item d-flex align-items-center justify-content-between ${defaultActive.map((data)=> data.profileId).indexOf(AMSprofiles.profileId) > -1 ? "active" : ''}`} >
                        <section className="d-flex align-items-center">
                          <img src={`https://img.mobiscroll.com/demos/flags/${AMSprofiles?.countryCode}.png`} alt="" className="wid-30 hei-25 mr-4" />

                            <div>
                              <h4 className="mb-2 f-w-600 f-14"> {AMSprofiles?.accountInfo?.name}</h4>
                              <p className="f-13 mb-0 f-w-500">{AMSprofiles.profileId}</p>
                            </div>
                        </section>
                      </Link>
                    </ListGroup.Item>
                  )}
                </ListGroup>

              </PerfectScrollbar>
              <div className="noti-footer py-2 text-left">
                <Link to="" className="mb-1">Manage Your Accounts</Link><br />
                <Link to="">Create a Manager Account</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        {/* } */}

        {/* <ListGroup.Item as="li" className="pr-4" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
              <div className="d-flex align-items-center">
                <img src={worldIcon} className="mr-2 wid-25" alt="world" />
                Global
              </div>
            </Dropdown.Toggle>
            <ul>
              <Dropdown.Menu>
                <li className="active">
                  <Link to="#" className="dropdown-item d-flex align-items-center">
                    <img src={worldIcon} className="mr-3 wid-30" alt="world" />
                    Global
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item d-flex align-items-center">
                    <img src={canadaFlag} className="mr-3 wid-30" alt="canadaflag" />
                    Canada
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item d-flex align-items-center">
                    <img src={usFlag} className="mr-3 wid-30" alt="usflag" />
                    US
                  </Link>
                </li>
                <li>
                  <Link to="#" className="dropdown-item d-flex align-items-center">
                    <img src={indiaFlag} className="mr-3 wid-30" alt="indiaflag" />
                    India
                  </Link>
                </li>
              </Dropdown.Menu>
            </ul>
          </Dropdown>
        </ListGroup.Item> */}

        {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Maximize size={18} onClick={enableFullscreen} className="cursor-pointer hover-theme" />
          <i className="feather icon-maximize cursor-pointer hover-theme" onClick={enableFullscreen}></i>
        </ListGroup.Item> */}

        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              {/* <i className="feather icon-bell icon" /> */}
              <Bell size={18} className="cursor-pointer hover-theme" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
              </div>
              {/* <PerfectScrollbar> */}
              {/* <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar1} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>John Doe</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>New ticket Added</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">EARLIER</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar2} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Joseph William</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar3} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Sara Soudein</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>currently login</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar4} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Suzen</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            yesterday
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                </ListGroup> */}
              {/* </PerfectScrollbar> */}
              <div className="noti-footer">
                <Link to="#">No Notifications here</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>

        {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown>
            <Dropdown.Toggle as={Link} variant="link" to="#" className="displayChatbox" onClick={() => setListOpen(true)}>
              <i className="icon feather icon-mail" />
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item> */}

        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user" >
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              {/* <i className="feather icon-user" /> */}
              <User size={18} className="cursor-pointer hover-theme" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head d-flex align-items-center f-w-700">
                {/* <img src={userImg} className="img-radius mr-3" alt="User Profile" /> */}
                <span>Admin GMB</span>
              </div>
              <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body list-pad">
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/profile" className="dropdown-item f-w-600 d-flex align-items-center">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/user-management" className="dropdown-item f-w-600 d-flex align-items-center">
                    <i className="feather icon-user" /> User Management
                  </Link>
                </ListGroup.Item> */}
                <ListGroup.Item as="li" bsPrefix=" ">
                  <a className="dropdown-item f-w-600 d-flex align-items-center" onClick={handleLogoutHandle}>
                    <i className="feather icon-log-out" /> Logout
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </React.Fragment>
  );
};

export default NavRight;



 {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item> */}
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item> */}
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-lock" /> Lock Screen
                  </Link>
                </ListGroup.Item> */}

{/* <ListGroup.Item as="li" bsPrefix=" " className="n-title px-0 pt-0">
                      <Link to="#" className="dropdown-item d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 f-w-600 f-14"> First Step Digital</h4>
                          <span className="f-13">United States</span>
                        </div>
                        <p className="f-13 f-w-500">A2XKCBGJ56TVDF</p>
                      </Link>
                    </ListGroup.Item>

                    <ListGroup.Item as="li" bsPrefix=" " className="n-title px-0 pt-0">
                      <Link to="#" className="dropdown-item d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 f-w-600 f-14">Nirogam</h4>
                          <span className="f-13">Canada</span>
                        </div>
                        <p className="f-13 f-w-500">A2XKCBGJ56TVDF</p>
                      </Link>
                    </ListGroup.Item>

                    <ListGroup.Item as="li" bsPrefix=" " className="n-title px-0 pt-0">
                      <Link to="#" className="dropdown-item d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 f-w-600 f-14">Nirogam</h4>
                          <span className="f-13">United States</span>
                        </div>
                        <p className="f-13 f-w-500">A2XKCBGJ56TVDF</p>
                      </Link>
                    </ListGroup.Item>

                    <ListGroup.Item as="li" bsPrefix=" " className="n-title px-0 pt-0">
                      <Link to="#" className="dropdown-item d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 f-w-600 f-14">Sirona Hygiene Private Limited</h4>
                          <span className="f-13">Maxico</span>
                        </div>
                        <p className="f-13 f-w-500">A2XKCBGJ56TVDF</p>
                      </Link>
                    </ListGroup.Item> */}
