import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from 'src/assets/images/common/logo.svg';
import gmbLogo from 'src/assets/images/common/logo_v3.svg';
import logoWhite from 'src/assets/images/common/white-logo.svg';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

const NavLogo = () => {
  const configContext = useContext(ConfigContext);
  const { collapseMenu } = configContext.state;
  const { dispatch } = configContext;

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <React.Fragment>
      <div className="navbar-brand header-logo">
        <Link to="#" className="b-brand">
          <div className="b-bg">
            <i className="feather icon-trending-up" />
          </div>
          <span className="b-title d-flex align-items-center">
          {/* <img src={gmbLogo} alt="logo" /> */}
          <p className="f-18 text-white f-w-800 m-l-10">GMB</p>
          </span>
        </Link>
        {/* <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
          <span />
        </Link> */}
      </div>
    </React.Fragment>
  );
};

export default NavLogo;
