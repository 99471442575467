import { PieChart, Target, Inbox, Command, Camera, User, Wind, Chrome } from 'react-feather';
import searchIcons from "src/assets/images/common/search.png";
const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Insights',
          url: '/insights',
          type: 'item',
          classes: 'nav-item',
          icon: <PieChart />
        },
        {
          id: 'location',
          title: 'Locations',
          url: '/locations',
          type: 'item',
          classes: 'nav-item',
          icon: <Target />
        },
        {
          id: 'managers',
          title: 'Managers',
          url: '/managers',
          type: 'item',
          classes: 'nav-item',
          icon: <Wind />
        },
        {
          id: 'accounts',
          title: 'Accounts',
          url: '/accounts',
          type: 'item',
          classes: 'nav-item',
          icon: <User />
        },
        {
          id: 'reviews',
          title: 'Reviews',
          url: '/reviews',
          type: 'item',
          classes: 'nav-item',
          icon: <Inbox />
        },
        {
          id: 'questions',
          title: 'Questions',
          url: '/questions',
          type: 'item',
          classes: 'nav-item',
          icon: <Command />
        },
        {
          id: 'posts',
          title: 'Posts',
          url: '/posts',
          type: 'item',
          classes: 'nav-item',
          icon: <Camera />
        },
        {
          id: 'searchKeywords',
          title: 'Search Keywords',
          url: '/keywords',
          type: 'item',
          classes: 'nav-item',
          icon: <Chrome />
        },
      ],
    },
  
  ]
};

export default menuItems;
