import React, { createContext, useReducer, useEffect } from 'react';
import Loader from '../components/Loader/Loader';
import Popper from 'src/components/Loader/LogoutPopper';


const user = JSON.parse(localStorage.getItem('User'))

const initialState = {
  isLoggedIn: user ? true : false,
  isInitialised: true,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'logged_status': {
      const { isLoggedIn, user } = action.payload;

      return {
        ...state,
        isLoggedIn,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  logout: () => Promise.resolve(),
  login: () => Promise.resolve
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);


  const removeLocalStorageValues = () => {
    if ('userAlreadyLogin' in localStorage) {
      var myItem = localStorage.getItem('userAlreadyLogin');
      localStorage.clear();
      localStorage.setItem('userAlreadyLogin', myItem);
    } else {
      localStorage.clear()
    }

  }

  const logout = () => {
    removeLocalStorageValues()
    return dispatch({
      type: 'logged_status',
      payload: {
        isLoggedIn: false,
        user: {}
      }
    });
  };

  const login = () => {
    const user = localStorage.getItem('User')
    const userDetails = JSON.parse(user)
    if (userDetails && userDetails.access_token) {
      dispatch({
        type: 'logged_status',
        payload: {
          isLoggedIn: true,
          user: userDetails
        }
      })
    }
  };

  useEffect(() => {
    const user = localStorage.getItem('User')
    const userDetails = JSON.parse(user)
    console.log("userDetails", userDetails)
    if (userDetails && userDetails.access_token) {
      dispatch({
        type: 'logged_status',
        payload: {
          isLoggedIn: true,
          user: userDetails
        }
      })
    }
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...state, logout, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
