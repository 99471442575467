import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';


export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin',
    component: lazy(() => import('./views/auth/signin/SignIn'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/insights',
        component: lazy(() => import('./views/pages/dashboards/index'))
      },
      {
        exact: true,
        path: '/insights/detail',
        component: lazy(() => import('./views/pages/dashboards/store-details'))
      },
      {
        exact: true,
        path: '/locations',
        component: lazy(() => import('./views/pages/locations/locations'))
      },
      {
        exact: true,
        path: '/reviews',
        component: lazy(() => import('./views/pages/reviews/reviews'))
      },
      {
        exact: true,
        path: '/questions',
        component: lazy(() => import('./views/pages/questions/questions'))
      },
      {
        exact: true,
        path: '/posts',
        component: lazy(() => import('./views/pages/posts/posts'))
      },
      {
        exact: true,
        path: '/accounts',
        component: lazy(() => import('./views/pages/accounts/accounts'))
      },
      {
        exact: true,
        path: '/managers',
        component: lazy(() => import('./views/pages/managers/managers'))
      },
      {
        exact: true,
        path: '/keywords',
        component: lazy(() => import('./views/pages/keyWords/keyWords'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={'/insights'} />
      }
    ]
  }
  
]
export default routes;
