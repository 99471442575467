import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Button = ({name, action, btnicn, btnright, classes, disabled, loader, type, children}) => {
  return (
    <React.Fragment>
        <button onClick={action} type={type} className={`d-flex align-items-center btn f-14 ${classes}`} disabled={disabled}> 
          {btnicn && <img src={btnicn} width="18" className="mr-2" alt="icon" />}
            {name} 
            {children}
          {btnright && <img src={btnright} width="18" className="ml-2" alt="icon" />}
          {loader && <Spinner animation="border" role="status" className="ml-2 wid-20 hei-20" variant="white" />  }
        </button>
    </React.Fragment>
  );
};

export default Button;
